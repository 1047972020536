export const landingPageStyles = {
  avatar: {
    width: {
      xs: 64,
      md: 96
    },
    height: {
      xs: 64,
      md: 96
    }
  },
  container: {
    height: {
      sm: '100%',
      xs: 'auto'
    },
    alignItems: 'stretch'
  },
  leftContainer: {
    position: 'relative',
    backgroundColor: {
      md: '#003077',
      xs: '#ffff'
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: `url(/images/background-login.png)`,
    backgroundPosition: 'left',
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: {
      xs: '100%',
      sm: 'auto'
    }
  },
  logo: {
    position: {
      xs: 'static'
    },
    top: 40,
    ml: {
      xs: 'auto',
      sm: '0px'
    },
    mt: {
      xs: 0,
      md: '14px'
    },
    mb: {
      xs: '14px',
      md: '48px'
    },
    mr: {
      xs: 'auto',
      sm: 'auto'
    },
    display: 'block',
    width: '160px'
  },
  wrapContent: {
    display: {
      xs: 'none',
      sm: 'block'
    }
  },
  cardLoanOfficer: {
    borderRadius: 2,
    padding: '20px 32px 32px 32px',
    border: '1px solid #E2E3E7',
    mt: {
      xs: 4,
      md: 8
    },
    mb: {
      xs: '12px',
      md: '80px'
    }
  },
  rightContent: {
    width: 440,
    maxWidth: '100%',
    mx: {
      xs: 'auto'
    },
    px: {
      sm: 0
    },
    pt: {
      xs: '24px',
      md: '88px'
    },
    minHeight: '100%',
    position: 'relative'
  },
  wrapper: {
    display: 'flex',
    minHeight: '100%',
    maxWidth: '100%'
  },
  rightContainer: {
    px: '40px',
    width: '100%'
  },
  textInput: {
    mt: 5,
    mb: 2
  },
  button: {
    display: 'block',
    mb: {
      xs: 4,
      md: 5
    }
  },
  haveAccountText: {
    textAlign: {
      xs: 'center',
      sm: 'left'
    },
    cursor: 'pointer'
  },
  leftContent: {
    px: '25px',
    width: '100%'
  },
  leftWrapper: {
    width: 410,
    maxWidth: '100%',
    mx: {
      xs: 'auto',
      md: 'auto'
    },
    mt: {
      xs: '0',
      md: '149px'
    }
  },
  list: {
    color: 'white',
    '& .MuiListItemButton-root': {
      pl: 0
    },
    '& .MuiButtonBase-root:hover': {
      backgroundColor: 'unset'
    }
  },
  link: {
    cursor: 'default'
  },
  nmlsWrapper: {
    display: {
      xs: 'none',
      md: 'flex'
    },
    flexDirection: 'column',
    alignItems: 'flex-end',
    height: '128px'
  },
  nmlsIcon: {
    width: '120px'
  },
  nmls: {
    position: 'absolute',
    bottom: '100px',
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
} as const;
