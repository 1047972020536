import {
  SendToMobile,
  UploadFileTwoTone,
  Calculate
} from '@mui/icons-material';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { Trans, useTranslation } from 'next-i18next';
import { landingPageStyles } from 'styles/landingPageStyles';

export const LandingLeftContent: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box sx={landingPageStyles.leftContent}>
      <Box sx={landingPageStyles.leftWrapper}>
        <Box
          component="img"
          sx={landingPageStyles.logo}
          src="/images/lighthouse_logo_b.png"
        />
        <Box sx={landingPageStyles.wrapContent}>
          <Typography variant="h3" color="white">
            <Trans i18nKey={'landing.welcome'}>
              Welcome! <br /> {"Let's start your application."}
            </Trans>
          </Typography>
          <Typography variant="body1" mt={3} mb={3} color="white">
            <Trans i18nKey={'landing.welcome_description'}></Trans>
          </Typography>
          <List sx={landingPageStyles.list}>
            <ListItem disablePadding>
              <ListItemButton sx={landingPageStyles.link} disableTouchRipple>
                <ListItemIcon>
                  <SendToMobile htmlColor="white" />
                </ListItemIcon>
                <ListItemText primary={t('landing.apply_online')} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={landingPageStyles.link} disableTouchRipple>
                <ListItemIcon>
                  <UploadFileTwoTone htmlColor="white" />
                </ListItemIcon>
                <ListItemText
                  primary={t('landing.upload_required_documents')}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={landingPageStyles.link} disableTouchRipple>
                <ListItemIcon>
                  <Calculate htmlColor="white" />
                </ListItemIcon>
                <ListItemText primary={t('landing.calculate_mortgage')} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  );
};
