import { SxProps } from '@mui/system';

type Styles = { [key: string]: SxProps };

export const headerTabs: Styles = {
  container: {
    mt: { xs: 15 },
    maxWidth: 921,
    borderBottom: 1,
    borderColor: 'divider'
  },
  tab: { flex: 1, maxWidth: 460 }
};
export const search: any = {
  container: () => ({
    mt: { xs: 10 },
    mb: { xs: 0 }
  })
};
export const footer: Styles = {
  mt7: { mt: 7 },
  container: {
    mt: { xs: 6, md: 15 }
  }
};

export const officerStyle: Styles = {
  title: { letterSpacing: '0.15px', pl: { xs: 6, md: 7.5 } },
  container: {
    border: '1px solid var(--tab-border-color)',
    borderRadius: 2,
    pt: { xs: 4, md: 7 },
    pb: { xs: 6, md: 8 },
    mt: { xs: 8, md: 15 }
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    alignItems: 'center',
    mt: { xs: 5, md: 6 }
  },
  avatar: { width: 96, height: 96, ml: { md: 7.5 }, mr: { md: 6 } },
  descriptionContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: { xs: 'center', md: 'flex-start' }
  },
  nameTitle: {
    pt: { xs: 4, md: 0 },
    lineHeight: '32px',
    color: 'var(--text-dark-color)'
  },
  titleDescription: {
    color: 'var(--input-text-color)',
    lineHeight: '20px',
    fontSize: 14,
    mb: { md: 2 }
  },
  emailContainer: {
    display: 'flex',
    alignItems: 'center',
    my: { xs: 2 }
  },
  email: {
    pl: { xs: 3, md: 2 },
    fontSize: 14,
    lineHeight: '20px'
  },
  phoneContainer: { display: 'flex', alignItems: 'center' },
  phone: {
    pl: { xs: 3, md: 2 },
    fontSize: 14,
    lineHeight: '20px'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: { xs: 'center', md: 'flex-start' },
    pl: { md: 7.5 }
  },
  button: { mt: { xs: 6, md: 8 }, maxHeight: '30px' },
  link: {
    display: 'flex',
    alignItems: 'center'
  }
};
