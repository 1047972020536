import * as yup from 'yup';
import { IValidationTrans } from './types';

export const LandingValidation: IValidationTrans = (t) => {
  const requiredText = t('required_field');
  const invalidEmail = t('invalid_email');
  return yup.object({
    email: yup.string().email(invalidEmail).required(requiredText)
  });
};

export const CalculatorValidation: IValidationTrans = (t) => {
  const requiredText = t('required_field');
  return yup.object({
    purchasePrice: yup.number().min(0.1, requiredText).required(requiredText),
    mortgageTerm: yup.number().min(0.1, requiredText).required(requiredText),
    downPayment: yup.number().optional().nullable().default(0),
    //.lessThan(yup.ref('purchasePrice'), t('invalid_down_payment')),
    annualTaxes: yup.number().min(0.1, requiredText).required(requiredText),
    interestRate: yup.number().min(0.1, requiredText).required(requiredText),
    annualInsurance: yup.number().min(0.1, requiredText).required(requiredText),
    monthlyHoa: yup.number().nullable().min(0, requiredText)
  });
};
