import { Mail, PhoneInTalk, Language } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Link as LinkMui
} from '@mui/material';
import { Box } from '@mui/system';
import { Trans, useTranslation } from 'next-i18next';
import Link from 'next/link';
import { officerStyle } from 'components/LoanOfficers/loanOfficerStyles';
import { TextInput } from 'components/TextInput/TextInput';
import { stepRoutes } from 'constants/step-routes';
import { landingPageStyles } from 'styles/landingPageStyles';
import {
  formatPhoneNumber,
  formatUriPhoneNumber,
  getFormikValidation
} from 'utils/commonUtils';

export const LandingRightContent: React.FC<{
  officer: any;
  formik: any;
}> = ({ officer, formik }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const branchNMLS = officer.officerInfo?.branches[0]?.nmls;

  return (
    <Box sx={landingPageStyles.rightContainer}>
      <Box sx={landingPageStyles.rightContent}>
        <Typography
          variant={isMobile ? 'h5' : 'h4'}
          textAlign={{
            xs: 'center',
            sm: 'left'
          }}
        >
          {t('landing.lets_get_started')}
        </Typography>
        <TextInput
          sx={landingPageStyles.textInput}
          name="email"
          label={t('landing.your_email_address')}
          onBlur={formik.handleBlur}
          error={getFormikValidation(formik, 'email', 'errors')}
          helperText={getFormikValidation(formik, 'email', 'helperText')}
          onChange={formik.handleChange}
          value={formik.values.email}
        />
        <LoadingButton
          loading={formik.isSubmitting}
          disabled={!formik.isValid}
          fullWidth={isMobile}
          onClick={formik.handleSubmit}
          size="large"
          variant="contained"
          data-testid="submit"
          sx={landingPageStyles.button}
        >
          {t('landing.get_started')}
        </LoadingButton>
        <Link href={stepRoutes.LOGIN_BORROWER.route}>
          <Typography
            component={'div'}
            sx={landingPageStyles.haveAccountText}
            variant="caption"
            color="primary.main"
          >
            <Trans i18nKey="landing.sign_in_here">
              Already have an account? <b>Sign in here</b>
            </Trans>
          </Typography>
        </Link>
        {!!officer.firstName && (
          <Box sx={landingPageStyles.cardLoanOfficer}>
            <Typography variant="subtitle2" color="text.secondary" mb={3}>
              {t('landing.loan_officer')}
            </Typography>
            <Grid container spacing={3}>
              <Grid item>
                <Avatar
                  src={officer.profilePicture || '/images/avatar-default.svg'}
                  sx={landingPageStyles.avatar}
                ></Avatar>
              </Grid>
              <Grid item xs>
                <Typography variant="h6" mt={1} color="#0B1838">
                  {`${officer.firstName} ${officer.lastName}`}
                </Typography>
                <Typography variant="body2" mb={1} color="text.secondary">
                  NMLS # {officer.officerInfo?.nmls}
                </Typography>
                {branchNMLS && (
                  <Typography variant="body2" mb={1} color="text.secondary">
                    {t('branch_nmls')} #{branchNMLS}
                  </Typography>
                )}
                {officer?.officerInfo?.urlName && (
                  <Box sx={officerStyle.emailContainer}>
                    <LinkMui
                      href={officer?.officerInfo?.urlName}
                      underline="none"
                      sx={officerStyle.link}
                      target="_blank"
                    >
                      <Language fontSize="small" color="primary" />
                      <Typography variant="body1" sx={officerStyle.email}>
                        {t('visit_site')}
                      </Typography>
                    </LinkMui>
                  </Box>
                )}
                <Box sx={officerStyle.emailContainer}>
                  <LinkMui
                    href={`mailto:${officer.email}`}
                    underline="none"
                    sx={officerStyle.link}
                  >
                    <Mail fontSize="small" color="primary" />
                    <Typography
                      data-testid="email_officer"
                      variant="body1"
                      sx={officerStyle.email}
                    >
                      {officer.email}
                    </Typography>
                  </LinkMui>
                </Box>
                <Box sx={officerStyle.phoneContainer}>
                  <LinkMui
                    href={formatUriPhoneNumber(officer?.primaryPhone)}
                    underline="none"
                    sx={officerStyle.link}
                  >
                    <PhoneInTalk fontSize="small" color="primary" />
                    <Typography
                      data-testid="primary_phone_officer"
                      variant="body1"
                      sx={officerStyle.phone}
                    >
                      {formatPhoneNumber(officer?.primaryPhone ?? '')}
                    </Typography>
                  </LinkMui>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        <Box sx={landingPageStyles.nmlsWrapper}>
          <Box sx={landingPageStyles.nmls}>
            <Box
              sx={landingPageStyles.nmlsIcon}
              component="img"
              src="/images/logo_full_mobile.png"
            />
            <Typography variant="body1" textAlign={'center'}>
              NMLS #33041
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
